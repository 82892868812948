var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
      attrs: { id: "schedule-practical" },
    },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#schedule-practical",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isMedicalExamSidebarActive,
            callback: function ($$v) {
              _vm.isMedicalExamSidebarActive = $$v
            },
            expression: "isMedicalExamSidebarActive",
          },
        },
        [
          _c("medical-exam-sidebar", {
            on: { closeSidebar: _vm.toggleMedicalExamSidebar },
          }),
        ],
        1
      ),
      _c("vue-context", { ref: "menu" }, [
        _vm.contextAdd
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("ADD_TIME")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "PlusCircleIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Adicionar Horário"),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.contextCopy
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("COPY")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "CopyIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("Copiar Grupo")]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.contextPaste
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("PASTE")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "FilePlusIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("Colar Grupo")]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.contextChange
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("CHANGE_TIME")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Alterar Horário"),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.contextDelete
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-danger",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("DELETE")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MinusCircleIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Excluir Agendamento"),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass:
            "no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0",
          class: { "sidebar-spacer": _vm.clickNotClose },
        },
        [
          _c(
            "div",
            { staticClass: "flex border d-theme-dark-bg items-center" },
            [
              _c("feather-icon", {
                staticClass:
                  "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleMedicalExamSidebar(true)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aluno(s)"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        multiple: "",
                        clearable: true,
                        filterable: true,
                        options: _vm.studentOptions,
                        placeholder: "Digite CPF ou nome do aluno...",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: { search: _vm.debouncedGetSearchStudent },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.students,
                        callback: function ($$v) {
                          _vm.students = $$v
                        },
                        expression: "students",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Funcionário"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.employee_id,
                        callback: function ($$v) {
                          _vm.employee_id = $$v
                        },
                        expression: "employee_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Clínica"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        clearable: true,
                        reduce: (option) => option.value,
                        options: _vm.cacs,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: { input: _vm.changeCac },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.cac_id,
                        callback: function ($$v) {
                          _vm.cac_id = $$v
                        },
                        expression: "cac_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Psicólogo"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        clearable: true,
                        reduce: (option) => option.value,
                        options: _vm.doctorsFiltered,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.doctor_id,
                        callback: function ($$v) {
                          _vm.doctor_id = $$v
                        },
                        expression: "doctor_id",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/3 w-full mt-0" }, [
                  _c(
                    "div",
                    { staticClass: "mt-4 flex flex-wrap items-center" },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "ml-auto mr-2 mt-1",
                          attrs: {
                            text: "Período do Relatório",
                            position: "top",
                          },
                        },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "cursor-pointer hover:text-primary",
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": "",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "flex cursor-pointer",
                                  attrs: { "href.prevent": "" },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "p-2 rounded-full",
                                    class: [`text-primary`, "mb-0"],
                                    style: {
                                      background: `rgba(var(--vs-success),.15)`,
                                    },
                                    attrs: {
                                      icon: "CalendarIcon",
                                      svgClasses: "h-6 w-6",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "vs-dropdown-menu",
                                {
                                  staticClass: "w-60",
                                  staticStyle: { "z-index": "200001" },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Inicial",
                                    },
                                    model: {
                                      value: _vm.reportFirstPeriod,
                                      callback: function ($$v) {
                                        _vm.reportFirstPeriod = $$v
                                      },
                                      expression: "reportFirstPeriod",
                                    },
                                  }),
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Final",
                                    },
                                    model: {
                                      value: _vm.reportLastPeriod,
                                      callback: function ($$v) {
                                        _vm.reportLastPeriod = $$v
                                      },
                                      expression: "reportLastPeriod",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.periodError,
                                          expression: "periodError",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        "O período inicial é maior que o final."
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-select", {
                        staticClass: "w-48 mr-2",
                        staticStyle: { "background-color": "white" },
                        attrs: {
                          clearable: false,
                          reduce: (option) => option.value,
                          options: [
                            { value: "date", label: "Ordem Data" },
                            { value: "student", label: "Ordem Aluno" },
                            { value: "employee", label: "Ordem Profissional" },
                          ],
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                  Nenhum resultado encontrado.\n                "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.orderBy,
                          callback: function ($$v) {
                            _vm.orderBy = $$v
                          },
                          expression: "orderBy",
                        },
                      }),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-2 mr-2",
                          attrs: { text: "Relatório Excel", position: "top" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-hover h-8 w-8 cursor-pointer",
                              staticStyle: {
                                "-ms-transform": "rotate(360deg)",
                                "-webkit-transform": "rotate(360deg)",
                                transform: "rotate(360deg)",
                              },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                "aria-hidden": "true",
                                focusable: "false",
                                width: "1em",
                                height: "1em",
                                preserveAspectRatio: "xMidYMid meet",
                                viewBox: "0 0 24 24",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.print("EXCEL")
                                },
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-2",
                          attrs: { text: "Relatório PDF", position: "top" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-hover h-8 w-8 cursor-pointer",
                              staticStyle: {
                                "-ms-transform": "rotate(360deg)",
                                "-webkit-transform": "rotate(360deg)",
                                transform: "rotate(360deg)",
                              },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                "aria-hidden": "true",
                                focusable: "false",
                                width: "0.86em",
                                height: "1em",
                                preserveAspectRatio: "xMidYMid meet",
                                viewBox: "0 0 1536 1792",
                              },
                              on: { click: _vm.doRelatorioPdf },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "mailListPS",
              tag: "component",
              staticClass: "email-content-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "div",
                {
                  staticClass: "vs-con-loading__container",
                  attrs: { id: "loading" },
                },
                _vm._l(_vm.tables, function (table, tIndex) {
                  return _c(
                    "vs-table",
                    {
                      key: tIndex,
                      staticClass: "bordered",
                      staticStyle: { overflow: "-webkit-paged-y" },
                      attrs: { data: _vm.selectedDays, hoverFlat: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return [
                                _c(
                                  "vs-tr",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      opacity: "1 !important",
                                      "vertical-align": "top",
                                    },
                                    attrs: { height: "30" },
                                  },
                                  _vm._l(
                                    _vm.handleRange(table),
                                    function (hr, itd) {
                                      return _c(
                                        "vs-td",
                                        {
                                          key: itd,
                                          nativeOn: {
                                            contextmenu: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.contextMenu(
                                                $event,
                                                hr - 1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.selectedDays[hr - 1]
                                              .holidaySchedule,
                                            function (holiday, hd) {
                                              return _c("feather-icon", {
                                                key: `${hd}_holiday`,
                                                staticClass: "mr-1",
                                                attrs: {
                                                  title: holiday.holidayName,
                                                  icon: "StarIcon",
                                                  svgClasses: "w-4 h-4",
                                                },
                                              })
                                            }
                                          ),
                                          _vm._l(
                                            _vm._.groupBy(
                                              _vm.selectedDays[hr - 1]
                                                .studentSchedule,
                                              "start_time"
                                            ),
                                            function (sc, hour) {
                                              return _c(
                                                "span",
                                                {
                                                  key: hour,
                                                  on: {
                                                    contextmenu: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.contextMenu(
                                                        $event,
                                                        hr - 1,
                                                        hour
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mb-1 font-semibold flex p-1 bg-secondary text-white cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addSchedule(
                                                            _vm.selectedDays[
                                                              hr - 1
                                                            ],
                                                            hour
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(hour))]
                                                  ),
                                                  _vm._l(
                                                    sc.sort((a, b) => {
                                                      if (
                                                        a.student &&
                                                        b.student
                                                      )
                                                        return a.student.name.localeCompare(
                                                          b.student.name
                                                        )
                                                    }),
                                                    function (schedule, idx) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: idx,
                                                          staticClass:
                                                            "cursor-pointer hover:text-primary hover:semibold",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.viewSchedules(
                                                                sc
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          schedule.student
                                                            ? _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "flex",
                                                                    on: {
                                                                      contextmenu:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.contextMenu(
                                                                            $event,
                                                                            -1
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        schedule.student &&
                                                                          schedule
                                                                            .student
                                                                            .name
                                                                          ? schedule
                                                                              .student
                                                                              .name
                                                                          : ""
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("hr"),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c("vs-divider"),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "vs-tr",
                                  {
                                    staticStyle: {
                                      opacity: "1 !important",
                                      border: "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        attrs: {
                                          colspan:
                                            _vm.handleRange(table).length + 1,
                                        },
                                      },
                                      [_c("vs-divider")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        _vm._l(_vm.handleRange(table), function (hr, i) {
                          return _c(
                            "vs-th",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "11px",
                                "min-width": "124px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.weekDays[
                                      _vm.selectedDays[hr - 1].weekdayPosition
                                    ]
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("dateTime")(
                                      _vm.selectedDays[hr - 1].id,
                                      "date"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mt-6 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-alert",
                    {
                      staticClass: "w-5/6",
                      staticStyle: { height: "-webkit-fill-available" },
                      attrs: {
                        color: "warning",
                        title: "Defina o período",
                        active: !_vm.tables,
                      },
                    },
                    [
                      _vm._v(
                        "\n              Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver\n              os agendamentos conforme o filtro superior.\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("medical-exam-view", {
        attrs: { isSidebarActive: _vm.isSidebarActive },
        on: { closeSidebar: _vm.closeViewSidebar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }