import state from './moduleSchedulePsychotechnicalState.js'
import mutations from './moduleSchedulePsychotechnicalMutations.js'
import actions from './moduleSchedulePsychotechnicalActions.js'
import getters from './moduleSchedulePsychotechnicalGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

