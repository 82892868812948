import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/psychotechnical`, payload)
        .then((response) => {
          commit('ADD_STUDENT_SCHEDULES', response.data) // ESTOU RETORNANDO UM ARRAY DE SCHEDULES
          commit('FILL_SELECTED_DAYS', payload.scheduleTime)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateMany ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/updateMany`, payload)
        .then((response) => {
          commit('UPDATE_SCHEDULES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilter ({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/get`, payload)
        .then((response) => {
          commit('SET_HOLIDAY_SCHEDULES', response.data.holidaySchedules)
          commit('SET_STUDENT_SCHEDULES', {
            schedules: response.data.studentSchedule,
            rootState
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/psychotechnical/delete`, payload)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
